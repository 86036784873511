export const environment = {
    production: true,
    apiUrl: 'https://acc.digibord.thiememeulenhoff.nl',
    sentryDsn:
        'https://89f74c0352c44a7d995f04fd8faba288@o37556.ingest.sentry.io/5728370',
    environment: 'acc',
    googleAnalyticsCode: 'G-GPKDYW6S0T',
    thasToken:
        '65b8216736ebe4638cd66a8ecf31a4fe7ff0aaf33c424c5307d8b208b07f82af',
};
